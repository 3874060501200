body {
    background-color: #eaeaea;
}

.odessa-nav-bar {
    margin: auto;
    max-width: 300px;
    right: auto !important;
    position: relative !important;
}

.odessa-logo {
    max-width: 100px;
    margin:auto;
}


.layout {
    background-color: #fff;
    margin-left:  40px;
    margin-right: 40px;
    margin-top: 20px;
    padding: 10px;
}

.new-section {
    margin-top: 20px;
}

.css-26l3qy-menu {
    z-index: 1000000 !important;
}

.right-aligned {
    float: right;
}

.odessa-text {
    color: #ff8f00;
}

.bold-text {
    font-weight: bold;
}

.centered-content {
    text-align: center;
}

.MuiButton-label {
    text-transform: capitalize;
}

.full-width {
    width: 100%;
    height: 100%;
}

.button-container {
    padding: 10px;
}